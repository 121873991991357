<template>
  <div>
    <ListHeader entity-name="quiz" router-name="QuizNew"></ListHeader>
    <Modal model="quiz" @confirm-deletion="deleteRecord"/>
    <div class="default-according style-1 faq-accordion default-accordion" id="accordionoc">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 m-b-20" v-for="(quiz, id) in records.items" :key="id">
            <b-card no-body>
              <CardHeader :id="id" model-name="Quiz" :record="quiz"
                          @sendRecord="sendRecord"></CardHeader>
              <b-collapse :id="`${id}`" role="tabpanel">
                <b-card-body class="pre">
                  <span class="col-12 row p-0 m-0">
                    <p class="col-3 p-r-0 mb-2">
                      <strong>{{ $t('models.quiz.attributes.questions_count') }}</strong>
                    </p>
                    <span class="col-9 mb-2">{{ quiz.questions.items.length }}</span>
                  </span>
                  <p class="col-3 p-r-0 mb-2">
                    <strong>{{ $t('models.quiz.attributes.instructions') }}</strong>
                  </p>
                  <p class="col-12 mb-10" v-text="quiz.truncated_instructions"></p>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="pagination" v-if="countNotZero">
            <pagination :options="options" :pages="pages" @paginate="paginate"/>
          </div>
          <div v-else>
            {{ $t('messages.error.noContent') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import ListsMixin from '../../mixins/ListsMixin';

export default {
  name: 'QuizList',
  data() {
    return {
      modelName: 'Quiz'
    };
  },
  computed: {
    ...mapGetters({
      records: 'quizzes'
    })
  },
  methods: {
    ...mapActions({
      getRecords: 'getQuizzes',
      destroyRecord: 'destroyQuiz'
    })
  },
  mixins: [ListsMixin]
};
</script>
